<template>
  <v-layout :class="['ma-1 delegate']" align-center v-ripple>
    <v-flex v-if="!deleteBtn" shrink @click.stop="handleSelection">
      <v-checkbox
        v-if="item.checkable"
        hide-details
        class="ma-0 pa-0 ml-2 zg-text-control"
        readonly
        v-model="selected"
      ></v-checkbox>
    </v-flex>
    <DefaultButton
      v-if="deleteBtn"
      icon
      small
      color="error"
      bgColor="transparent"
      class="ma-0 pa-0"
      @click.prevent="handleSelection"
    >
      <v-icon small>mdi-trash-can-outline</v-icon>
    </DefaultButton>
    <v-layout
      align-center
      @click.prevent="handleSelectionIfSelectable"
      fill-height
    >
      <v-icon
        v-if="!item.selectable && !hideOpenIcon"
        small
        :class="['pr-0', item.checkable ? 'ml-0' : 'ml-2']"
        >{{ openIcon }}</v-icon
      >
      <DefaultSubText
        class="mr-4 ml-2 text-truncate"
        v-html="sanitize(text)"
      ></DefaultSubText>
    </v-layout>
  </v-layout>
</template>

<script>
import URO_NAMES from "@/constants/patientcard/uro";
import PHY_NAMES from "@/constants/patientcard/physio";
import PED_NAMES from "@/constants/patientcard/pediatric";
import _ from "lodash";
import sanitizeHtml from 'sanitize-html';

export default {
  props: {
    item: {},
    open: {
      type: Boolean,
      default: false,
    },
    paired: {
      type: Array,
      default: () => [],
    },
    opened: {
      type: Array,
      default: () => [],
    },
    hideOpenIcon: {
      type: Boolean,
      default: false,
    },
    deleteBtn: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    open() {
      if (this.open && !this.internalOpened.includes(this.item.altId)) {
        this.internalOpened.push(this.item.altId);
      } else if (!this.open) {
        const index = this.internalOpened.indexOf(this.item.altId);
        if (index !== -1) {
          this.internalOpened.splice(index, 1);
        }
      }
    },
  },
  computed: {
    internalPaired: {
      get() {
        return this.paired;
      },
      set(value) {
        this.$emit("update:paired", value);
      },
    },
    index() {
      return _.findIndex(this.paired, {
        id: this.item.id,
        category: this.item.category,
      });
    },
    selected() {
      return this.index !== -1;
    },
    text() {
      if (this.item.label) {
        return `<b>${this.item.label}</b>`;
      } else if (PHY_NAMES[this.item.name]) {
        return `<b>${PHY_NAMES[this.item.name]}</b>`;
      } else if (URO_NAMES[this.item.name]) {
        return `<b>${URO_NAMES[this.item.name]}</b>`;
      } else if (PED_NAMES[this.item.name]) {
        return `<b>${PED_NAMES[this.item.name]}</b>`;
      } else {
        return `<b>${this.item.title}</b>`;
      }
    },
    openIcon() {
      return this.open ? "mdi-chevron-up" : "mdi-chevron-down";
    },
    internalOpened: {
      get() {
        return this.opened;
      },
      set(value) {
        this.$emit("update:opened", value);
      },
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
  },
  methods: {
    sanitize(text) {
      return sanitizeHtml(text);
    },
    handleSelection() {
      if (this.index === -1) {
        this.internalPaired.push(this.item);
      } else {
        this.internalPaired.splice(this.index, 1);
      }
    },
    handleSelectionIfSelectable() {
      if (this.item.selectable) {
        this.handleSelection();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.delegate {
  border-radius: 8px !important;
  border: 1px solid rgba(0,0,0,.1) !important;
  height: 30px !important;
}
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>