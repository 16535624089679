import { render, staticRenderFns } from "./PredefinedPairDelegate.vue?vue&type=template&id=7ab752c2&scoped=true&"
import script from "./PredefinedPairDelegate.vue?vue&type=script&lang=js&"
export * from "./PredefinedPairDelegate.vue?vue&type=script&lang=js&"
import style0 from "./PredefinedPairDelegate.vue?vue&type=style&index=0&id=7ab752c2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ab752c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VFlex,VIcon,VLayout})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
